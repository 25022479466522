import { Layout, Menu, MenuProps } from "antd"
import Search from "antd/lib/input/Search"
import { useContext, useEffect, useState } from "react"
import { Link, Outlet } from "react-router-dom"
import { QueryContext } from "../api/QueryContext"
import { SiteBodyWithSideNav } from "../site/SiteBodyWithSideNav"

const { Sider, Content } = Layout

export default function Report() {
  const queries = useContext(QueryContext)
  const { data } = queries.useReports()

  const [searchQuery, setSearchQuery] = useState("")

  const [filteredReports, setFilteredReports] = useState(data)

  const searchHandler = (value: string) => {
    setSearchQuery(value.toLowerCase())
  }

  useEffect(() => {
    let filteredReports = data
    setFilteredReports(
      searchQuery === ""
        ? data
        : filteredReports?.filter(
            (report) =>
              report.title?.toLowerCase().includes(searchQuery) ||
              (report.tags ?? [])
                .map((x) => x.tagName.toLowerCase())
                .includes(searchQuery)
          )
    )
  }, [searchQuery, data])

  const items: MenuProps["items"] = filteredReports
    ?.sort((a, b) => a.title?.localeCompare(b.title!) ?? 0)
    .map((report) => ({
      label: (
        <>
          <Link to={`${report.id}/report`} /> {report.title}
        </>
      ),
      key: report.id,
    }))
  return (
    <SiteBodyWithSideNav>
      <Layout hasSider>
        <Sider
          width={350}
          style={{
            overflow: "auto",
            height: "calc(100vh-85px)",
            position: "fixed",
            left: 0,
            top: "64px",
            bottom: 0,
          }}
        >
          <Search
            placeholder="Search for reports...."
            onSearch={searchHandler}
          />
          <Menu theme="dark" items={items} />
        </Sider>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Outlet />
        </Content>
      </Layout>
    </SiteBodyWithSideNav>
  )
}
