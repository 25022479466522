import { createContext } from "react"
import { QueryClient, useQuery, UseQueryOptions } from "react-query"
import {
  Client,
  IReportSchedulePost,
  ParameterValues,
  ReportData,
  ReportDetail,
  ReportDetailsPost,
  ReportRequest,
  ReportSchedule,
  ReportSchedulePost,
  ReportTag,
} from "./api"

export class Queries {
  public queryClient: QueryClient
  public api: Client

  constructor(apiUrl: string, getAuthorization: () => string) {
    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })
    this.api = new Client({ getAuthorization }, apiUrl)
  }

  useReports = () => {
    return useQuery(["report"], () => this.api.reports_GetReports())
  }

  useReportData = (
    reportId: number,
    newParameterValues: ParameterValues[],
    lastRunValues: ParameterValues[] | undefined,
    onSettled?: (data: ReportData | undefined, error: unknown) => void
  ) => {
    var request = new ReportRequest({ id: reportId, parameters: lastRunValues })
    return useQuery(
      ["reportData", reportId, newParameterValues],
      () => this.api.reports_GetReportData(request),
      {
        enabled:
          newParameterValues === lastRunValues && lastRunValues !== undefined,
        staleTime: 1000 * 60 * 3, //3 minutes
        onSettled: onSettled,
      }
    )
  }

  getReportCsv = async (reportId: number, sqlParams: ParameterValues[]) => {
    var request = new ReportRequest({ id: reportId, parameters: sqlParams })
    return await this.api.reports_GetReportCSV(request)
  }

  useReport = (
    reportId: number,
    options?:
      | Omit<
          UseQueryOptions<
            ReportDetail,
            unknown,
            ReportDetail,
            (string | number)[]
          >,
          "queryKey" | "queryFn"
        >
      | undefined
  ) => {
    return useQuery(
      ["reportData", reportId],
      () => this.api.reports_GetReportDetails(reportId),
      options
    )
  }

  getReportSchedule = (
    id: number,
    options?:
      | Omit<
          UseQueryOptions<
            ReportSchedule,
            unknown,
            ReportDetail,
            (string | number)[]
          >,
          "queryKey" | "queryFn"
        >
      | undefined
  ) => {
    return useQuery(["reportSchedule", id], () =>
      this.api.reportSchedules_GetReportSchedule(id)
    )
  }

  getReportSchedules = () => {
    return useQuery(["reportSchedules"], () =>
      this.api.reportSchedules_GetReportScheduleList()
    )
  }

  getReportData = (
    reportId: number,
    runReportParams: ParameterValues[] | undefined
  ) =>
    useQuery(
      ["reportData", reportId, runReportParams],
      () =>
        this.api.reports_GetReportData(
          new ReportRequest({
            id: reportId,
            parameters: runReportParams,
          })
        ),
      {
        enabled: false,
        staleTime: 1000 * 60 * 3, //3 minutes
      }
    )

  getTags = () => {
    return useQuery(["tagData"], () => this.api.reportTags_GetTags())
  }

  postReportSchedule = (reportSchedule: IReportSchedulePost) =>
    this.api.reportSchedules_Post(new ReportSchedulePost(reportSchedule))

  postReportDetails = (id: number, reportDetailsPost: ReportDetailsPost) =>
    this.api.reports_UpdateReportDetails(id, reportDetailsPost)

  postReportTag = (id: number, reportTag: ReportTag) =>
    this.api.reportTags_PostTag(id, reportTag)

  putReportSchedule = (id: number, reportSchedule: ReportSchedule) =>
    this.api.reportSchedules_UpdateReportSchedule(id, reportSchedule)

  getParameterDataOld = (id: number, parentValues: string[] | undefined) => {
    if (!parentValues) {
      return this.api
        .reports_GetParameterData(id, [] as string[])
        .then((response) => response.parameterValues)
    }

    if (parentValues.length === 0) {
      return this.api
        .reports_GetParameterData(id, [] as string[])
        .then((response) => response.parameterValues)
    }

    var firstValue = parentValues[0]

    if (!firstValue) {
      return this.api
        .reports_GetParameterData(id, [] as string[])
        .then((response) => response.parameterValues)
    }
    return this.api
      .reports_GetParameterData(id, parentValues as any)
      .then((response) => response.parameterValues)
  }

  getParameterData = (id: number, parentValues: string[] | undefined) => {
    var safeParentValues =
      !parentValues || parentValues.length === 0 || !parentValues[0]
        ? []
        : parentValues

    return useQuery(
      ["parameterData"],
      () => this.api.reports_GetParameterData(id, safeParentValues),
      {
        staleTime: 1000 * 60 * 5,
      }
    )
  }

  getParameterDataAsPromise = (
    id: number,
    parentValues: string[] | undefined
  ): Promise<ParameterValues[]> => {
    var safeParentValues =
      !parentValues || parentValues.length === 0 || !parentValues[0]
        ? []
        : parentValues
    return this.api
      .reports_GetParameterData(id, safeParentValues)
      .then((x) => x.parameterValues)
  }

  getAcademicYears = () => {
    return useQuery(
      ["academicYears"],
      () => this.api.academicYear_AcademicYears(),
      {
        staleTime: 1000 * 60 * 5,
      }
    )
  }
  getIdCardHolders = (academicYearId: number | undefined = undefined) => {
    return useQuery(
      ["idCardHolders", academicYearId],
      () =>
        academicYearId ? this.api.idCard_IdCardHolders(academicYearId) : [],
      {
        staleTime: 1000 * 60 * 5,
        enabled: academicYearId != undefined,
      }
    )
  }
  getIdCardHolderImage = (id: number | undefined) => {
    return useQuery(
      ["idCardHolder", id],
      () => this.api.idCard_IdCardHolderImage(id!),
      {
        staleTime: 1000 * 60 * 5,
        enabled: id != undefined,
      }
    )
  }

  invalidateQuery = (id: string) => {
    this.queryClient.invalidateQueries({ queryKey: [id] })
  }
}

export const QueryContext = createContext<Queries>(undefined!)
