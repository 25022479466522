import { useState, useEffect } from "react";
import { DatePicker, Select, Button } from "antd";
const { Option } = Select;

import { ParameterOutput, ParameterValues } from "./types";
import dayjs from "dayjs";

type DateParameterProps = {
  parameter: ParameterOutput;
  isImmediate: boolean;
  selectedValue?: ParameterValues | undefined;
  onValueChange?: (
    parameterId: number,
    newValue: string | string[] | undefined
  ) => void;
};

export const DateParameter = ({
  parameter,
  selectedValue,
  onValueChange,
  isImmediate,
}: DateParameterProps) => {
  const presets = [
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "LastWeek", label: "Last Week" },
    { value: "LastMonth", label: "Last Month" },
    { value: "ThisWeek", label: "This Week" },
    { value: "ThisComingWeek", label: "This Coming Week" },
  ];

  const getOffsetDateString = (daysOffset: number, endOfDay: boolean): string => {
    const date = new Date();

    // Adjust the date by the specified offset in days
    date.setDate(date.getDate() + daysOffset);

    if (endOfDay) {
      // Set time to 23:59:59.999 for the end of the day
      date.setHours(23, 59, 59, 999);
    } else {
      // Set time to 00:00:00.000 for the start of the day
      date.setHours(0, 0, 0, 0);
    }

    // Format the date as yyyy-MM-dd HH:mm:ss.SSS
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const getLastMonthDate = (endOfMonth: boolean): string => {
    const now = new Date();
        
    // Start of current month
    const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    var date = new Date(startOfCurrentMonth);

    // Set to the last day of last month
    date.setDate(date.getDate() - 1);

    if (endOfMonth) {
      date.setHours(23, 59, 59, 999);
    }
    else {
      // set to the first day of last month
      date = new Date(date.getFullYear(), date.getMonth(), 1);
      date.setHours(0, 0, 0, 0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const getCurrentWeekDate = (weekOffset: number, endOfWeek: boolean): string => {
    const now = new Date();

    // Calculate the difference from Monday
    const dayOfWeek = now.getDay() + (weekOffset * 7);
    const differenceFromMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If today is Sunday (0), go back 6 days

    // Start of the week (Monday)
    const date = new Date(now);
    date.setDate(now.getDate() + differenceFromMonday);
    date.setHours(0, 0, 0, 0);

    if (endOfWeek) {
      date.setDate(date.getDate() + 6);
      date.setHours(23, 59, 59, 999);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const immediatePresets = [
    { label: "Today", value: parameter.name === "start_date" ? getOffsetDateString(0, false) : getOffsetDateString(0, true) },
    { label: "Yesterday", value: parameter.name === "start_date" ? getOffsetDateString(-1, false) : getOffsetDateString(-1, true) },
    { label: "LastWeek", value: parameter.name === "start_date" ? getCurrentWeekDate(-1, false) : getCurrentWeekDate(-1, true) },
    { label: "LastMonth", value: parameter.name === "start_date" ? getLastMonthDate(false) : getLastMonthDate(true) },
    { label: "ThisWeek", value: parameter.name === "start_date" ? getCurrentWeekDate(0, false) : getCurrentWeekDate(0, true) },
    { label: "ThisComingWeek", value: parameter.name === "start_date" ? getCurrentWeekDate(1, false) : getCurrentWeekDate(1, true) },
  ];

  const isPreset = presets.some((x) => x.value === selectedValue?.value);
  const [showDatePicker, setShowDatePicker] = useState(!isPreset);
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const dayjsDefaultValue = dayjs(selectedValue?.value);

  useEffect(() => {
    setShowDatePicker(!isPreset);
    if (isPreset) {
      setSelectedPreset(selectedValue?.value!);
    }
  }, [selectedValue]);

  const handlePresetChange = (preset: string) => {
    setSelectedPreset(preset);
    onValueChange?.(parameter.id, preset);
  };

  return (
    <div>
      <Button onClick={() => setShowDatePicker(!showDatePicker)}>
        {showDatePicker ? "Select Preset" : "Select Date"}
      </Button>

      {showDatePicker ? (
        <DatePicker
          id={parameter.name}
          value={
            dayjsDefaultValue.isValid() && selectedValue !== undefined
              ? dayjsDefaultValue
              : undefined
          }
          onChange={(_, dateString) => {
            selectedValue = { label: parameter.name, value: dateString };
            onValueChange?.(parameter.id, dateString);
          }}
        />
      ) : (
        <Select
          key={selectedPreset}
          style={{ width: "149px" }}
          onChange={handlePresetChange}
          value={selectedPreset}
          options={isImmediate ? immediatePresets : presets}
        ></Select>
      )}
    </div>
  );
};
