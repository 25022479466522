import { Button, Col, Row, Spin, Flex } from "antd";
import { ColumnType } from "antd/lib/table";
import { Key, useState } from "react";
import { ParameterSelector } from "./ParameterSelector";
import { ColumnDefinition, ParameterOutput, ParameterValues } from "./types";
import { TableViewer } from "./TableViewer";
import ReportViewer from "./DevExpressViewer";

export type ReportTableViewProps = BaseReportViewProps & {
  data: { [key: string]: string }[] | undefined;
  columns: ColumnDefinition[] | undefined;
  isLoading: boolean | undefined;
};

export type DevExpressReportViewProps = BaseReportViewProps & {
  host: string;
  runReport: boolean;
};

export type BaseReportViewProps = {
  reportId: Key | null | undefined;
  parameters: ParameterOutput[] | undefined;
  parameterValues: ParameterValues[];
  error: unknown;
  onValueChange?:
    | ((parameterId: number, newValue: string | string[] | undefined) => void)
    | undefined;
  getParameterData: (
    id: number,
    parentValues: string[] | undefined
  ) => Promise<ParameterValues[]>;
  onRunReportClicked: () => void;
  downloadCsv: () => void;
};

export type ReportViewProps = ReportTableViewProps | DevExpressReportViewProps;

export const prettyLabelNames: { [id: string]: string } = {
  start_date: "Start Date",
  StartDate: "Start Date",
  startdate: "Start Date",
  end_date: "End Date",
  EndDate: "End Date",
  enddate: "End Date",
  CreatedOnStart: "Turned Live On Start Date",
  CreatedOnEnd: "Turned Live On End Date",
  ICC: "ICC",
  clientaccountid: "Client Account",
  ClientAccountID: "Client Account",
  clientaccountID: "Client Account",
  DimClientClientAccountId: "Client Account",
  clientaccountId: "Client Account",
  ClientId: "Client",
  ClientID: "Client",
  clientid: "Client",
  clientId: "Client",
  Client_Id: "Client",
  client_id: "Client",
  clientID: "Client",
  client: "Client",
  P_ClientId: "Client",
  application_status: "Application Status",
  applicationstatus: "Application Status",
  ClientModuleId: "Client Module",
  ClientModuleGroupID: "Client Module Group",
  clientmoduleID: "Client Module",
  clientmoduleGroupID: "Client Module Group",
  clientmodulegroup: "Client Module Group",
  clientmodulegroupid: "Client Module Group",
  SalesRegionID: "Sales Region",
  Region: "Sales Region",
  PayCycle: "Pay Cycle",
  PayCycleId: "Pay Cycle",
  PayCycleID: "Pay Cycle",
  paycycle: "Pay Cycle",
  payCycleId: "Pay Cycle",
  region: "Region",
  Year: "Year",
  academicyear: "Academic Year",
  CandidateStatus: "Candidate Status",
  salesRegion: "Sales Region",
  salesregion: "Sales Region",
  salesregionid: "Sales Region",
  Salesregionid: "Sales Region",
  PayRunID: "Pay Run",
  DimPayRunPayRunId: "Pay Run",
  linedate: "Line Date",
  Linedate: "Line Date",
  processPayDateTo: "Process Pay Date To",
  DimDateBudgetControlAcademicYear: "Academic Year",
  AssignmentHeaderId: "Assignment Header Id",
  NoteTypeId: "Note Type",
  accountcalendarID: "Account Calendar",
  accountcostcentre: "Cost Centre",
  candidateId: "Candidate",
  site: "Site",
  ConsultantID: "Consultant",
  RTWDocumentType: "RTW Document Type",
  Month: "Month",
};

export const isReportTableViewProps = (
  reportViewProps: ReportViewProps
): reportViewProps is ReportTableViewProps => {
  const reportTableViewProps = reportViewProps as ReportTableViewProps;
  return reportTableViewProps.isLoading !== undefined;
};

export const ReportDataView = (reportDataViewProps: ReportViewProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const parameterControls: JSX.Element[] = [];

  reportDataViewProps.parameters
    ?.filter((x) => x.parentId === null || x.parentId === undefined)
    .map((parameter) => {
      const parameterName = prettyLabelNames[parameter.name ?? ""];
      const parameterNameChild = prettyLabelNames[parameter.child?.name ?? ""];

      return parameterControls.push(
        <>
          <div>
            <label style={{ paddingRight: 10, paddingLeft: 10 }}>
              {`${parameterName} ${
                parameterNameChild ? ": " + parameterNameChild : ""
              }`}
            </label>
            {ParameterSelector({
              parameter,
              getParameterData: reportDataViewProps.getParameterData,
              parentValues: undefined,
              selectedValues: undefined,
              onValueChange: reportDataViewProps.onValueChange,
              hasInternalState: true
            })}
          </div>
        </>
      );
    });

  const hasValuesForAllParameters = () =>
    reportDataViewProps.parameters?.length ===
    reportDataViewProps.parameterValues.length;

  const handleRunReport = () => {
    reportDataViewProps.onRunReportClicked();
  };

  const handleDownloadClicked = async () => {
    setIsDownloading(true);
    await reportDataViewProps.downloadCsv();
    setIsDownloading(false);
  };

  const tableViewer = (props: ReportTableViewProps) => {
    var transformedColumns = props.columns?.map<ColumnType<any>>((element) => ({
      key: element.id,
      title: element.title,
      dataIndex: element.title,
    }));

    return (
      <TableViewer
        columns={transformedColumns}
        data={props.data}
        isLoading={props.isLoading}
        reportId={props.reportId}
        key={props.reportId}
      />
    );
  };
  var viewer = isReportTableViewProps(reportDataViewProps) ? (
    tableViewer(reportDataViewProps)
  ) : (
    <ReportViewer
      parameters={reportDataViewProps.parameters}
      reportId={reportDataViewProps.reportId}
      paramValues={reportDataViewProps.parameterValues}
      runReport={reportDataViewProps.runReport}
      host={reportDataViewProps.host}
    />
  );

  var runReportButton = (
    <Button
      onClick={handleRunReport}
      style={{ float: "right" }}
      disabled={!hasValuesForAllParameters()}
    >
      Run Report
    </Button>
  );
  var downloadButton = isReportTableViewProps(reportDataViewProps) ? (
    isDownloading ? (
      <Button
        onClick={handleDownloadClicked}
        style={{ float: "right" }}
        disabled={true}
      >
        <Spin spinning={true} tip="Downloading " size="small" />
      </Button>
    ) : (
      <Button
        onClick={handleDownloadClicked}
        style={{ float: "right" }}
        disabled={!hasValuesForAllParameters() || isDownloading}
      >
        Download
      </Button>
    )
  ) : undefined;

  return (
    <Flex vertical gap="small" style={{ height: "100%" }}>
      {reportDataViewProps.error ? (
        <pre>{JSON.stringify(reportDataViewProps.error)}</pre>
      ) : undefined}
      <h3>Options</h3>
      <Flex justify="space-between">
        <div>{parameterControls ? parameterControls : undefined}</div>
        <Flex vertical>
          {runReportButton}
          {downloadButton}
        </Flex>
      </Flex>
      <Flex style={{ height: "100%" }} justify="center" align="stretch">
        {viewer}
      </Flex>
    </Flex>
  );
};
