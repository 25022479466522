import { Key, useLayoutEffect, useRef } from "react";
import {
  DxReportViewer,
  ExportFormatID,
  PreviewElements,
} from "devexpress-reporting/dx-webdocumentviewer";
import { ParameterValues, ParameterOutput } from "./types";
import { Empty } from "antd";

export type ReportViewerProps = {
  parameters: ParameterOutput[] | undefined;
  paramValues: ParameterValues[];
  reportId: Key | null | undefined;
  runReport: boolean;
  host: string;
};

const ReportViewer = (props: ReportViewerProps) => {
  const urlParameters = new URLSearchParams();
  props.paramValues.forEach((x) => {
    if (x.label !== undefined && x.value !== undefined) {
      urlParameters.append(x.label!, x.value!);
    }
  });
  const reportUrl = `${props.reportId}?${urlParameters.toString()}`;
  const viewerRef = useRef<HTMLDivElement>(null);
  const requestOptions = {
    host: new URL(props.host).href,
    invokeAction: "DXXRDV",
  };

  useLayoutEffect(() => {
    var viewer = viewerRef.current
      ? new DxReportViewer(viewerRef.current, {
          reportUrl,
          requestOptions,
          callbacks: {
            DocumentReady(sender) {
              var preview = sender.GetPreviewModel();
              preview.reportPreview.zoom(1);
            },
            CustomizeExportOptions(s, e) {
              e.HideFormat(ExportFormatID.CSV);
              e.HideFormat(ExportFormatID.HTML);
              e.HideFormat(ExportFormatID.Image);
              e.HideFormat(ExportFormatID.MHT);
              e.HideFormat(ExportFormatID.RTF);
              e.HideFormat(ExportFormatID.Text);
              e.HideFormat(ExportFormatID.XLS);
            },
            CustomizeElements(s, e: any) {
              var panelPart = e.GetById(PreviewElements.RightPanel);
              var index = e.Elements.indexOf(panelPart);
              e.Elements.splice(index, 1);
            },
          },
        })
      : null;
    if (viewerRef.current && props.runReport) {
      viewerRef.current.innerText = "";
      viewer?.render();
    }
  }, [props.runReport, props.reportId]);

  return props.parameters?.length === props.paramValues.length ||
    props.runReport ? (
    <div
      id="ReportDiv"
      key={props.reportId}
      ref={viewerRef}
      style={{ width: "100%" }}
    ></div>
  ) : (
    <Empty description="No Data - Provide Options" />
  );
};

export default ReportViewer;
