import { PublicClientApplication } from "@azure/msal-browser"
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react"
import { Menu, MenuProps } from "antd"
import { Header } from "antd/lib/layout/layout"
import { Link } from "react-router-dom"
import logo from "./protocol-logo.svg"
import "./site.css"

const items: MenuProps["items"] = [
  {
    label: <Link to="/reports">Reports</Link>,
    key: "1",
  },
  {
    label: <Link to="/idcards">ID Cards</Link>,
    key: "2",
  },
]

interface SiteHeaderProps {
  publicClientApplication: PublicClientApplication
}
export function SiteHeader({ publicClientApplication: pca }: SiteHeaderProps) {
  return (
    <>
      <Header>
        <img className="logo" src={logo} alt="Protocol Logo" />
        <MsalProvider instance={pca}>
          <AuthenticatedTemplate>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              items={items?.concat([
                {
                  label: "Logout",
                  key: "3",
                  onClick: () => {
                    pca.logoutRedirect()
                  },
                },
              ])}
            />
          </AuthenticatedTemplate>
        </MsalProvider>
      </Header>
    </>
  )
}
