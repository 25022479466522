import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser"
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Button } from "antd"
import { useEffect, useState } from "react"
import { QueryClientProvider } from "react-query"
import { Outlet } from "react-router-dom"
import { Queries, QueryContext } from "../api/QueryContext"
import { SiteConfiguration } from "../configuration"
import { fetchSetup } from "@devexpress/analytics-core/analytics-utils"

const authRequest = {
  scopes: ["api://1371dc69-d0b0-4fdc-95f1-6e2deb8e68bd/user_impersonation"],
}

interface SiteBodyProps {
  configuration: SiteConfiguration
  publicClientApplication: PublicClientApplication
}

export const SiteBody = ({
  configuration,
  publicClientApplication: pca,
}: SiteBodyProps) => {
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <AuthenticatedContent {...configuration} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
        <Button onClick={() => pca.loginRedirect()}>Login</Button>
      </UnauthenticatedTemplate>
    </MsalProvider>
  )
}

const AuthenticatedContent = (configuration: SiteConfiguration) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(
    accounts.filter(
      (x) => x.tenantId === "99ee51fb-0cdd-40c9-bd96-143fa7db25ed"
    )[0] ||
      accounts[0] ||
      {}
  )
  const [accessToken, setAccessToken] = useState<string | undefined>()
  const [queries, setQueries] = useState<Queries>()

  useEffect(() => {
    if (account) {
      let tokenToBeSet: AuthenticationResult | undefined = undefined
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account: account,
          authority:
            "https://login.microsoftonline.com/99ee51fb-0cdd-40c9-bd96-143fa7db25ed",
        })
        .catch(async (err) => {
          if (err instanceof InteractionRequiredAuthError) {
            var token = await instance.acquireTokenPopup({
              scopes: authRequest.scopes,
              loginHint: account.username,
              account: account,
            })
            tokenToBeSet = token
          }
        })
        .then((response) => {
          if (tokenToBeSet === undefined) {
            tokenToBeSet = response!
          }
          setAccessToken(tokenToBeSet?.accessToken)
          instance.setActiveAccount(account)
        })
    }
  }, [account, instance])

  useEffect(() => {
    if (accessToken) {
      setQueries(
        new Queries(configuration.apiUrl, () => `Bearer ${accessToken}`)
      )
      fetchSetup.fetchSettings = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    }
  }, [accessToken, configuration.apiUrl])

  if (
    inProgress === InteractionStatus.None &&
    accessToken !== undefined &&
    queries
  ) {
    return (
      <QueryContext.Provider value={queries}>
        <QueryClientProvider client={queries.queryClient}>
          <Outlet />
        </QueryClientProvider>
      </QueryContext.Provider>
    )
  }
  return <>Logging In</>
}
