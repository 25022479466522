import { ReportDataView } from "./ReportDataView";

export { ParentChildSelect } from "./ParentChildSelect";
export { DateParameter } from "./DateParameter";
export { ParameterSelector } from "./ParameterSelector";
export type { ParameterSelectorProps } from "./ParameterSelector";
export type { ParameterValues } from "./types";
export { ReportDataView, prettyLabelNames } from "./ReportDataView";
export type { BaseReportViewProps, ReportViewProps } from "./ReportDataView";

const gt = globalThis as any;
gt.ReportDataView = ReportDataView;
