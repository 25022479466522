import { useContext, useRef } from "react"
import { QueryContext } from "../api/QueryContext"
import { Image, Typography } from "antd"
import { defaultImage } from "../site/fallbackImage"
const { Text } = Typography
export default function ProfileImage(props: {
  userAccountId: number | undefined
}) {
  const queries = useContext(QueryContext)
  const image = queries.getIdCardHolderImage(props.userAccountId)

  let imageUrl = ""
  if (props.userAccountId) {
    if (image.data) {
      imageUrl = URL.createObjectURL(image.data?.data)
    }
  }

  return (
    <Image
      height={100}
      src={imageUrl}
      placeholder={<Image src={defaultImage} height={100} />}
      fallback={defaultImage}
    />
  )
}
