import { ParentChildSelect } from "./ParentChildSelect";
import { DateParameter } from "./DateParameter";
import { InputNumberParameter } from "./InputNumberParameter";
import { InputParameter } from "./InputParameter";
import { ParameterOutput, ParameterValues } from "./types";
import { DataSetSingleSelect } from "./DataSetSingleSelect";

export type ParameterSelectorProps = {
  parameter: ParameterOutput;
  getParameterData: (
    id: number,
    parentValues: string[] | undefined
  ) => Promise<ParameterValues[]>;
  selectedValues?: ParameterValues[] | ParameterValues | undefined;
  parentValues?: ParameterValues[] | undefined;
  onValueChange?:
    | ((parameterId: number, newValue: string | string[] | undefined) => void)
  | undefined;
    hasInternalState: boolean
};

export const ParameterSelector = ({
  parameter,
  getParameterData,
  selectedValues,
  parentValues,
  onValueChange,
  hasInternalState,
}: ParameterSelectorProps) => {
  const singleSelectedValue = Array.isArray(selectedValues)
    ? selectedValues[0]
    : selectedValues;

  if (parameter.child !== undefined && parameter.child !== null) {
    return (
      <ParentChildSelect
        key={parameter.name}
        parameter={parameter}
        getParameterData={getParameterData}
        selectedValues={selectedValues}
        parentValues={parentValues}
        onValueChange={onValueChange}
        hasInternalState={hasInternalState}
      />
    );
  }
  switch (parameter.dataType) {
    case "StringValue":
      return (
        <InputParameter
          parameter={parameter}
          selectedValue={singleSelectedValue}
          onValueChange={onValueChange}
        />
      );
    case "IntValue":
      return (
        <InputNumberParameter
          parameter={parameter}
          selectedValue={singleSelectedValue}
          onValueChange={onValueChange}
        />
      );
    case "DataSetMultiSelect":
      return (
        <DataSetSingleSelect
          parameter={parameter}
          getParameterData={getParameterData}
          isMultiSelect={true}
          selectedValues={selectedValues}
          parentValues={parentValues}
          onValueChange={onValueChange}
        />
      );
    case "DataSetSingleSelect":
      return (
        <DataSetSingleSelect
          parameter={parameter}
          getParameterData={getParameterData}
          isMultiSelect={false}
          selectedValues={singleSelectedValue}
          parentValues={parentValues}
          onValueChange={onValueChange}
        />
      );
    case "DateTime":
      return (
        <DateParameter
          parameter={parameter}
          selectedValue={singleSelectedValue}
          onValueChange={onValueChange}
          isImmediate={hasInternalState}
        />
      );
    default:
      return <>No Control for {parameter.dataType}</>;
  }
};
