import { CopyOutlined, IdcardOutlined } from "@ant-design/icons"
import { Card, Col, Row } from "antd"
import Title from "antd/lib/typography/Title"
import { useNavigate } from "react-router-dom"

export default function Home() {
  const navigate = useNavigate()
  return (
    <>
      <Row justify="center" gutter={16}>
        <Col span={8}>
          <Title>Welcome to Backoffice</Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col span={8}>
          <Card hoverable onClick={() => navigate("reports")}>
            <CopyOutlined />
            Reports
          </Card>
        </Col>
        <Col span={8}>
          <Card hoverable onClick={() => navigate("idcards")}>
            <IdcardOutlined />
            IdCards
          </Card>
        </Col>
      </Row>
    </>
  )
}
