import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { Key } from "react";

export type TableViewerProps<T = any> = {
  reportId: Key | null | undefined;
  data: { [key: string]: string }[] | undefined;
  columns: ColumnType<T>[] | undefined;
  isLoading: boolean | undefined;
};

export const TableViewer = (tableViewerProps: TableViewerProps) => {
  return (
    <Table
      key={tableViewerProps.reportId}
      dataSource={tableViewerProps.data}
      columns={tableViewerProps.columns}
      loading={tableViewerProps.isLoading}
      style={{ width: "100%" }}
    />
  );
};
