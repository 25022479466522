import { Button, Col, Row, Select, Tag } from "antd"
import type { CustomTagProps } from "rc-select/lib/BaseSelect"
import { useContext, useEffect, useState } from "react"
import { ReportDetailsPost, ReportTag } from "../api/api"
import { AppRoles } from "../api/Permissions/AppRoles"
import { useIsAuthorized } from "../api/Permissions/RouteGuard"
import { QueryContext } from "../api/QueryContext"

interface props {
  reportIdNumber: number
}
export default function ReportTags(props: props) {
  const queries = useContext(QueryContext)
  const { data: reportData, refetch: reportRefetch } = queries.useReport(
    props.reportIdNumber
  )
  const { data: tagData } = queries.getTags()
  const [tags, setTags] = useState<ReportTag[]>([])
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const canEditTags = useIsAuthorized({ roles: [AppRoles.TagsEdit] })

  useEffect(() => {
    setTags(reportData?.tags ?? [])
  }, [reportData])

  useEffect(() => {
    setButtonDisabled(reportData?.tags === tags)
  }, [tags, reportData])

  const handleChange = (value: string[]) => {
    var reportTags: ReportTag[] = value.map((x) => {
      const existingReportTag = tagData?.filter((y) => y.tagName === x)
      if (existingReportTag !== undefined && existingReportTag.length > 0) {
        return existingReportTag[0]
      } else {
        return new ReportTag({
          reportTagId: 0,
          tagName: x,
          requiresPermission: false,
        })
      }
    })

    setTags(reportTags)
  }

  const handleButtonClick = async () => {
    await queries.postReportDetails(
      props.reportIdNumber,
      new ReportDetailsPost({ tags: tags })
    )

    reportRefetch()
  }

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={"default"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ borderRadius: 10, padding: "3px 15px" }}
      >
        {label}
      </Tag>
    )
  }

  return (
    <>
      <Row className="reportTags">
        <Col span={22}>
          <Select
            disabled={!canEditTags}
            bordered={false}
            className="reportTagsSelect"
            mode="tags"
            tagRender={tagRender}
            value={tags.map((x) => {
              return x.tagName
            })}
            options={(tagData || []).map((x) => ({
              value: x.tagName,
              label: x.tagName,
            }))}
            onChange={handleChange}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col span={2}>
          <Button
            onClick={handleButtonClick}
            disabled={buttonDisabled}
            hidden={!canEditTags}
            style={{ float: "right" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}
