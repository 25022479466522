import { useEffect, useState } from "react";
import { Select } from "antd";
import { ParameterOutput, ParameterValues, ParameterChildOutput } from "./types";

type DataSetSingleSelectProps = {
  getParameterData: (id: number, parentValues: string[] | undefined) => Promise<ParameterValues[]>;
  parameter: ParameterOutput | ParameterChildOutput;
  isMultiSelect: boolean;
  selectedValues?: ParameterValues[] | ParameterValues | undefined;
  parentValues?: ParameterValues[] | undefined;
  onValueChange?: ((parameterId: number, newValue: string | string[] | undefined) => void) | undefined;
};

export const DataSetSingleSelect = ({
  getParameterData,
  parameter,
  isMultiSelect,
  selectedValues,
  parentValues,
  onValueChange
}: DataSetSingleSelectProps) => {
  const [options, setOptions] = useState<ParameterValues[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  
  useEffect(() => {
    if ((parameter.parentId !== null && parameter.parentId !== undefined) && (!parentValues || (parentValues && parentValues.length === 0))) {
      setIsDisabled(true);
    }
    else {
      getParameterData(parameter.id, parentValues?.map(x => x.value!))
        .then((fetchedParameterValues) => {
          setIsDisabled(false);
          setOptions([...fetchedParameterValues]);
        })
        .catch((error) => {
          console.error('Error fetching parameter values:', error);
        });
    }
  }, [parentValues, parameter.id]);

  return (
    <Select
      style={{ width: "500px" }}
      showSearch
      value={isMultiSelect
        ? (Array.isArray(selectedValues) ? selectedValues.map(x => x.value) : selectedValues?.value)
        : (Array.isArray(selectedValues) ? selectedValues[0]?.value : selectedValues?.value)}
      disabled={isDisabled}
      mode={isMultiSelect ? "multiple" : undefined}
      onChange={(value) => onValueChange?.(parameter.id, Array.isArray(value) ? value.map(x => x!) : value?.toString())}
      options={options.map((param) => ({
        label: param.label, 
        value: param.value, 
      }))}
      filterOption={(input, option) =>
        option!.value!.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option!.label!.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};
