import dayjs from "dayjs"
import {
  DayOfWeek,
  IReportSchedule,
  ParameterOutput,
  ScheduledType,
} from "../../api/api"

export interface IEditableSchedule extends ISchedule {
  id: number
}

export interface Parameter {
  name: string
  value?: string
  options: ParameterOutput
}

export interface ISchedule {
  reportId: number
  name: string
  description: string | undefined

  emailTo: string[]
  emailCc: string[] | undefined
  emailBcc: string[] | undefined
  emailSubject: string
  includeLink: boolean

  scheduledType: ScheduledType
  daysOfTheWeek: DayOfWeek[] | undefined
  dayOfTheMonth: number | undefined

  timeToRun: dayjs.Dayjs
}

export const defaultISchedule: IEditableSchedule = {
  id: -1,
  reportId: -1,
  name: "",
  description: "",
  emailTo: [""],
  emailCc: [""],
  emailBcc: [""],
  emailSubject: "",
  includeLink: false,
  scheduledType: ScheduledType.DayOfWeek,
  daysOfTheWeek: [],
  dayOfTheMonth: 1,
  timeToRun: dayjs(new Date(2000, 1, 1, 0, 0), "hh:ss"),
}

export function Convert(
  reportSchedule: IReportSchedule | undefined
): IEditableSchedule {
  const splitEmail = (s: string | undefined) =>
    s && s.length > 0 ? s.split(";") : [""]

  return reportSchedule
    ? {
        id: reportSchedule.id,
        reportId: reportSchedule.reportId,
        name: reportSchedule.name,
        description: reportSchedule.description,
        emailTo: splitEmail(reportSchedule.emailTo),
        emailCc: splitEmail(reportSchedule.emailCc),
        emailBcc: splitEmail(reportSchedule.emailBcc),
        emailSubject: reportSchedule.emailSubject,
        includeLink: reportSchedule.includeLink,
        scheduledType: reportSchedule.scheduledType,
        daysOfTheWeek: reportSchedule.daysOfTheWeek,
        dayOfTheMonth: reportSchedule.dayOfTheMonth,
        timeToRun: dayjs(reportSchedule.timeToRun, "hh:mm:ss"),
      }
    : defaultISchedule
}
