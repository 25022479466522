import { Layout, Progress } from "antd"
import { PropsWithChildren } from "react"
import { useIsFetching } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

function GlobalLoadingIndicator() {
  const isFetching = useIsFetching()

  return (
    <Progress
      percent={100}
      status={isFetching ? "active" : "success"}
      showInfo={false}
    />
  )
}

export const SiteBodyWithSideNav: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Layout
      className="site-layout"
      style={{
        overflow: "auto",
        height: "calc(100vh-85px)",
        top: "64px",
        bottom: 0,
        marginLeft: "350px",
      }}
    >
      <GlobalLoadingIndicator />
      {children}
      <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
    </Layout>
  )
}
