import { DataSetSingleSelect } from "./DataSetSingleSelect";
import { ParameterOutput, ParameterValues } from "./types";
import { useState, useEffect } from "react";
import { DateParameter } from "./DateParameter";
import { InputNumberParameter } from "./InputNumberParameter";
import { InputParameter } from "./InputParameter";

type ParentChildSelectProps = {
  getParameterData: (
    id: number,
    parentValues: string[] | undefined
  ) => Promise<ParameterValues[]>;
  parameter: ParameterOutput;
  selectedValues?: ParameterValues[] | ParameterValues | undefined;
  parentValues?: ParameterValues[] | undefined;
  onValueChange?:
    | ((parameterId: number, newValue: string | string[] | undefined) => void)
    | undefined;
  hasInternalState: boolean;
};

export const ParentChildSelect = ({
  getParameterData,
  parameter,
  selectedValues,
  parentValues: propParentValues,
  onValueChange,
  hasInternalState
}: ParentChildSelectProps) => {
  const [parentValues, setParentValues] = useState(propParentValues);

  const handleValueChange = (
    parameterId: number,
    newValue: string | string[] | undefined
  ) => {
    if (parameterId === parameter.id && hasInternalState) {
      setParentValues(
        Array.isArray(newValue)
          ? (newValue.map((x) => ({
            label: parameter.name,
            value: x,
          })) as ParameterValues[])
          : newValue
            ? ([{ label: parameter.name, value: newValue }] as ParameterValues[])
            : undefined
      );
    }
    onValueChange?.(parameterId, newValue);
  };

  const realParentValues = hasInternalState ? parentValues : propParentValues;

  const singleParentValue = Array.isArray(realParentValues)
    ? (realParentValues)[0]
    : (realParentValues);

  switch (parameter.dataType) {
    case "StringValue":
      return (
        <div>
          <InputParameter
            parameter={parameter}
            selectedValue={singleParentValue}
            onValueChange={handleValueChange}
          />
          {parameter.child && (
            <DataSetSingleSelect
              parameter={parameter.child}
              getParameterData={getParameterData}
              isMultiSelect={parameter.child.dataType === "DataSetMultiSelect"}
              selectedValues={selectedValues}
              parentValues={realParentValues}
              onValueChange={handleValueChange}
            />
          )}
        </div>
      );
    case "IntValue":
      return (
        <div>
          <InputNumberParameter
            parameter={parameter}
            selectedValue={singleParentValue}
            onValueChange={handleValueChange}
          />
          {parameter.child && (
            <DataSetSingleSelect
              parameter={parameter.child}
              getParameterData={getParameterData}
              isMultiSelect={parameter.child.dataType === "DataSetMultiSelect"}
              selectedValues={selectedValues}
              parentValues={realParentValues}
              onValueChange={handleValueChange}
            />
          )}
        </div>
      );
    case "DataSetMultiSelect":
      return (
        <div>
          <DataSetSingleSelect
            parameter={parameter}
            getParameterData={getParameterData}
            isMultiSelect={true}
            selectedValues={realParentValues}
            onValueChange={handleValueChange}
          />
          {parameter.child && (
            <DataSetSingleSelect
              parameter={parameter.child}
              getParameterData={getParameterData}
              isMultiSelect={parameter.child.dataType === "DataSetMultiSelect"}
              selectedValues={selectedValues}
              parentValues={realParentValues}
              onValueChange={handleValueChange}
            />
          )}
        </div>
      );
    case "DataSetSingleSelect":
      return (
        <div>
          <DataSetSingleSelect
            parameter={parameter}
            getParameterData={getParameterData}
            isMultiSelect={false}
            selectedValues={realParentValues}
            onValueChange={handleValueChange}
          />
          {parameter.child && (
            <DataSetSingleSelect
              parameter={parameter.child}
              getParameterData={getParameterData}
              isMultiSelect={parameter.child.dataType === "DataSetMultiSelect"}
              selectedValues={selectedValues}
              parentValues={realParentValues}
              onValueChange={handleValueChange}
            />
          )}
        </div>
      );
    case "DateTime":
      return (
        <div>
          <DateParameter
            parameter={parameter}
            selectedValue={singleParentValue}
            onValueChange={handleValueChange}
            isImmediate={hasInternalState}
          />
          {parameter.child && (
            <DataSetSingleSelect
              parameter={parameter.child}
              getParameterData={getParameterData}
              isMultiSelect={parameter.child.dataType === "DataSetMultiSelect"}
              selectedValues={selectedValues}
              parentValues={realParentValues}
              onValueChange={handleValueChange}
            />
          )}
        </div>
      );
    default:
      return <>No Control for {parameter.dataType}</>;
  }
};
