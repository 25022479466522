import { LockOutlined, UnlockOutlined } from "@ant-design/icons"
import { Card, Col, Row, Space, Tooltip } from "antd"
import { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Report, ReportTag } from "../api/api"
import { AppRoles } from "../api/Permissions/AppRoles"
import { useIsAuthorized } from "../api/Permissions/RouteGuard"
import { QueryContext } from "../api/QueryContext"

interface IGroups {
  tag: ReportTag
  reports: Report[]
}
export default function ReportSummary() {
  const queries = useContext(QueryContext)
  const { data: reportData, refetch: refetchReportData } = queries.useReports()
  const { data: tagData, refetch: refetchTagData } = queries.getTags()
  const canEditTags = useIsAuthorized({ roles: [AppRoles.TagsEdit] })
  const navigate = useNavigate()
  const handleCardNavigate = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, report: number) =>
      navigate(`${report}/report`),
    [navigate]
  )

  const taggedGroups: IGroups[] =
    tagData
      ?.sort((a, b) => a.tagName?.localeCompare(b.tagName!) ?? 0)
      .map((tag) => {
        const reports = reportData?.filter(
          (x) => x.tags?.find((t) => t.tagName === tag.tagName) !== undefined
        )
        return {
          tag: tag,
          reports: reports ?? [],
        }
      }) ?? []

  const untaggedGroup: IGroups = {
    tag: new ReportTag({
      tagName: "Not Tagged",
      requiresPermission: false,
      reportTagId: -1,
    }),
    reports:
      reportData
        ?.sort((a, b) => a.title?.localeCompare(b.title!) ?? 0)
        .filter((x) => x.tags?.length === 0) ?? [],
  }

  async function buttonHandler(
    event: React.MouseEvent<HTMLSpanElement>,
    o: ReportTag
  ) {
    event.preventDefault()
    o.requiresPermission = !o.requiresPermission
    await queries.postReportTag(o.reportTagId, o)
    refetchTagData()
    refetchReportData()
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      {taggedGroups
        .concat(untaggedGroup)
        .filter((x) => canEditTags || x.reports.length > 0)
        .map((group) => (
          <Card
            key={group.tag.tagName}
            title={
              <>
                {group.tag.tagName}{" "}
                {group.tag.reportTagId > 0 && canEditTags ? (
                  <Tooltip title="Makes this tag a permissions based tag, the user will need to be assigned the correct role to see reports in this group">
                    {group.tag.requiresPermission ? (
                      <LockOutlined
                        onClick={(e) => buttonHandler(e, group.tag)}
                      />
                    ) : (
                      <UnlockOutlined
                        onClick={(e) => buttonHandler(e, group.tag)}
                      />
                    )}
                  </Tooltip>
                ) : undefined}
              </>
            }
          >
            <Row gutter={16}>
              {group.reports.map((report) => (
                <Col span={6} key={"col" + report.id}>
                  <Card
                    key={"card" + report.id}
                    style={{
                      cursor: "pointer",
                      marginTop: 8,
                      backgroundColor: "#001529",
                      color: "hsla(0,0%,100%,.65)",
                    }}
                    onClick={(e) => handleCardNavigate(e, report.id)}
                  >
                    {report.title}
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ))}
    </Space>
  )
}
