import { Input } from "antd";
import { ParameterOutput, ParameterValues } from "./types";

type InputParameterProps = {
  parameter: ParameterOutput;
  selectedValue: ParameterValues | undefined;
  onValueChange?: (parameterId: number, newValue: string | string[] | undefined) => void;
};

export const InputParameter = ({
  parameter,
  selectedValue,
  onValueChange
}: InputParameterProps) => (
  <Input
    value={selectedValue?.value || ''}
    onChange={(event) => { onValueChange?.(parameter.id, event.target.value) }}
  />
);
