import { Configuration } from "@azure/msal-browser"

export interface SiteConfiguration extends Configuration {
  apiUrl: string
}

var conf: SiteConfiguration = (window as any).config
conf.auth.redirectUri = window.location.origin
conf.auth.authority =
  "https://login.microsoftonline.com/99ee51fb-0cdd-40c9-bd96-143fa7db25ed"
//Configuration file can be found in public folder, this is done to enable the values to be replaced during deployment
export const configuration: SiteConfiguration = conf
