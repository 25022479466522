import {
  Button,
  Table,
  Layout,
  DatePicker,
  Form,
  Switch,
  Dropdown,
  Select,
  Collapse,
} from "antd"
import Card from "antd/lib/card/Card"
import { Content } from "antd/lib/layout/layout"
import Sider from "antd/lib/layout/Sider"
import { ColumnsType } from "antd/lib/table"
import Paragraph from "antd/lib/typography/Paragraph"
import download from "downloadjs"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { ErrorResult, IdCardIssuedVm, IIdCardHolderVm } from "../api/api"
import { QueryContext } from "../api/QueryContext"
import { SiteBodyWithSideNav } from "../site/SiteBodyWithSideNav"
import ProfileImage from "./ProfileImage"

type IdCardHolderGridModel = IIdCardHolderVm & {
  currentCard: IdCardIssuedVm | undefined
}
export default function IdCards() {
  const queries = useContext(QueryContext)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [academicYear, setAcademicYear] = useState<number | undefined>(
    undefined
  )
  const [error, setError] = useState<string | undefined>(undefined)
  const cardHolders = queries.getIdCardHolders(academicYear)
  const academicYears = queries.getAcademicYears()

  const data: IdCardHolderGridModel[] | undefined = cardHolders.data
    ?.map((x) => {
      var latestCard =
        x.cardsIssued?.length > 0
          ? x.cardsIssued?.reduce((a, b) => (a.expiresOn > b.expiresOn ? a : b))
          : undefined

      return {
        currentCard:
          latestCard && latestCard.expiresOn > new Date()
            ? latestCard
            : undefined,
        ...x,
      }
    })
    .sort((x) => x.userAccountId)

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IdCardHolderGridModel[]
    ) => {
      setSelectedIds(selectedRowKeys.map((x) => parseInt(x.toString())))
      setSelectedNames(selectedRows.map((x) => x.name))
    },
    getCheckboxProps: (record: IdCardHolderGridModel) => ({
      disabled: !record.cardCanBeIssued || !record.hasPicture,
      userAccountId: record.userAccountId,
    }),
  }

  const handleButtonClick = async (values: any) => {
    setIsGenerating(true)
    setError(undefined)
    try {
      var zip = await queries.api.idCard_PrintIdCards(
        selectedIds,
        values.expires.toDate(),
        values.blankCard
      )
      download(zip.data, zip.fileName, "application/zip")
      cardHolders.refetch()
    } catch (ex) {
      var message = ex as ErrorResult
      setError(message.error)
    }
    setIsGenerating(false)
  }

  const columns: ColumnsType<IdCardHolderGridModel> = [
    {
      title: "Has Current Card",
      dataIndex: "hasCurrentCard",
      key: "hasCurrentCard",
      render(_, record) {
        return record.currentCard ? "Yes" : "No"
      },
      filters: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      onFilter: (value, record) =>
        value === "yes"
          ? record.currentCard !== undefined
          : record.currentCard === undefined,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      key: "image",
      render(record) {
        return <ProfileImage userAccountId={record.userAccountId} />
      },
      shouldCellUpdate(record, prevRecord) {
        return record.userAccountId !== prevRecord.userAccountId
      },
      filters: [
        { text: "Has Image", value: "yes" },
        { text: "No Image", value: "no" },
      ],
      onFilter: (value, record) => {
        switch (value) {
          case "yes":
            return record.hasPicture
          case "no":
            return !record.hasPicture
        }
        return false
      },
    },
    {
      title: "Lecturer Id",
      dataIndex: "userAccountId",
      key: "userAccountId",
    },
    {
      title: "Cards Issued",
      dataIndex: "cardsIssued",
      key: "cardsIssued",
      render(_, record, i) {
        return (
          <Collapse>
            <Collapse.Panel
              key={i}
              header={
                record.currentCard
                  ? "Expires: " + record.currentCard.expiresOn.toDateString()
                  : "No Current Card"
              }
            >
              {record.cardsIssued.map((x) => (
                <Paragraph key={x.id}>
                  {`Issued: ${x.issuedOn.toDateString()}`}
                  <br />
                  {`Expires: ${x.expiresOn.toDateString()}`}
                </Paragraph>
              ))}
            </Collapse.Panel>
          </Collapse>
        )
      },
    },
  ]

  const handleChange = (value: number) => {
    setAcademicYear(value)
  }

  const now = dayjs()
  const nextAug = now.month() > 7 ? now.add(1, "year").year() : now.year()

  const nextExpiryDate = dayjs(`${nextAug}-08-31`, "YYYY-MM-DD")
  return (
    <SiteBodyWithSideNav>
      <Layout hasSider>
        <Sider
          width={350}
          style={{
            overflow: "auto",
            height: "calc(100vh-85px)",
            position: "fixed",
            left: 0,
            top: "64px",
            bottom: 0,
          }}
        >
          <Card>
            <Paragraph>Academic Year</Paragraph>
            <Select
              onChange={handleChange}
              style={{ width: "150px" }}
              defaultValue={academicYear}
              options={academicYears.data?.map((x) => ({
                value: x.academicYearId,
                label: x.academicYearDescription,
              }))}
            ></Select>
            <Paragraph>
              Selected {selectedNames.length} Cards to print
            </Paragraph>
            <Form
              name="generatePdf"
              onFinish={handleButtonClick}
              initialValues={{
                expires: nextExpiryDate,
                blankCard: false,
              }}
            >
              <Form.Item
                label="Blank Card"
                name="blankCard"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Expires On"
                name="expires"
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={selectedIds.length === 0}
                  loading={isGenerating}
                >
                  Generate Id Cards
                </Button>
              </Form.Item>
              {error ? <Card>{error}</Card> : undefined}
            </Form>
          </Card>
        </Sider>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Table
            rowKey={(x) => x.userAccountId}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            scroll={{ y: "75vh" }}
          />
        </Content>
      </Layout>
    </SiteBodyWithSideNav>
  )
}
