import { InputNumber } from "antd";
import { ParameterOutput, ParameterValues } from "./types";

type InputNumberParameterProps = {
  parameter: ParameterOutput;
  selectedValue?: ParameterValues | undefined;
  onValueChange?: (parameterId: number, newValue: string | string[] | undefined) => void;
};

export const InputNumberParameter = ({
  parameter,
  selectedValue,
  onValueChange
}: InputNumberParameterProps) => (
  <InputNumber
    value={selectedValue?.value}
    onChange={(value) => { onValueChange?.(parameter.id, value?.toString() ) }}
  />
);
