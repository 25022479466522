import { useAccount, useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

export interface ProtectedObjectProps {
  roles: string[]
}

export const RouteGuard = (props: ProtectedObjectProps) => {
  const { instance, accounts } = useMsal()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    if (account && account.idTokenClaims!["roles"]) {
      let idTokenClaims = account.idTokenClaims!["roles"] as string[]

      let intersection = props.roles.filter((role) =>
        idTokenClaims.includes(role)
      )

      if (intersection.length > 0) {
        setIsAuthorized(true)
      }
    }
  }, [account, instance, props.roles])

  return (
    <>
      {isAuthorized ? (
        <Outlet />
      ) : (
        <div className="data-area-div">
          <h3>You are unauthorized to view this content.</h3>
        </div>
      )}
    </>
  )
}

export function useIsAuthorized(props: ProtectedObjectProps) {
  const account = useAccount()
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (account && account.idTokenClaims!["roles"]) {
      let idTokenClaims = account.idTokenClaims!["roles"] as string[]
      let intersection = props.roles.filter((role) =>
        idTokenClaims.includes(role)
      )

      if (intersection.length > 0) {
        setIsAuthorized(true)
      }
    }
  }, [account, props.roles])

  return isAuthorized
}
