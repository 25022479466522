import {
  BaseReportViewProps,
  ParameterValues,
  ReportDataView,
} from "@protocol/pn.backoffice.components/build"
import { Flex, Spin } from "antd"
import download from "downloadjs"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { QueryContext } from "../api/QueryContext"
import { ParameterValues as ApiParameterValues, ReportType } from "../api/api"
import { configuration } from "../configuration"

export default function ReportData() {
  const [currentParameterValues, setCurrentParameterValues] = useState<
    ParameterValues[]
  >([])
  const [runReportParams, setRunReportParams] = useState<
    ParameterValues[] | undefined
  >(undefined)
  let { reportId } = useParams()

  useEffect(() => {
    setCurrentParameterValues([])
    setRunReportParams(undefined)
  }, [reportId])

  const reportIdNumber = parseInt(reportId ?? "")
  const queries = useContext(QueryContext)
  const reportQuery = queries.useReport(reportIdNumber)

  const { data, error, isLoading, refetch } = queries.getReportData(
    reportIdNumber,
    runReportParams?.map((x) => new ApiParameterValues(x)) ?? []
  )

  const onValueChange = (
    parameterId: number,
    newValue: string | string[] | undefined
  ) => {
    const myParameter = reportQuery.data?.parameters.find(
      (x) => x.id === parameterId
    )
    const itemIndex = currentParameterValues.findIndex(
      (x) => x.label === myParameter?.name
    )

    // Create a new array with all existing sqlParams
    let newSqlParams = [...currentParameterValues]

    if (Array.isArray(newValue) ? newValue?.length > 0 : newValue) {
      const newParamValue = Array.isArray(newValue)
        ? newValue.join(",")
        : newValue
      if (itemIndex === -1) {
        // Add new parameter
        newSqlParams.push({ label: myParameter?.name, value: newParamValue })
      } else {
        // Update existing parameter
        newSqlParams[itemIndex] = {
          ...newSqlParams[itemIndex],
          value: newParamValue,
        }
      }
    } else {
      // Remove parameter if newValue is null or undefined
      if (itemIndex !== -1) {
        newSqlParams.splice(itemIndex, 1)
      }
    }

    // Update the state with the new array
    setCurrentParameterValues(newSqlParams)
  }
  const onRunReportClicked = async () => {
    await setRunReportParams(currentParameterValues)
    refetch()
  }

  if (reportQuery.isFetching) {
    return (
      <Flex
        style={{ width: "100%", height: "360px" }}
        justify="center"
        align="center"
      >
        <Spin />
      </Flex>
    )
  }

  const downloadCsv = async () => {
    const csv = await queries.getReportCsv(
      reportIdNumber,
      currentParameterValues.map((x) => new ApiParameterValues(x))
    )
    download(csv.data, csv.fileName ?? reportQuery.data?.title, "text/csv")
  }

  var props: BaseReportViewProps = {
    downloadCsv: downloadCsv,
    onRunReportClicked: onRunReportClicked,
    getParameterData: (id: number, parentValues: string[] | undefined) =>
      queries.getParameterDataAsPromise(id, parentValues),
    parameters: reportQuery.data?.parameters,
    reportId: reportId,
    error: error,
    onValueChange: onValueChange,
    parameterValues: currentParameterValues,
  }

  if (reportQuery.data?.reportType === ReportType.Table) {
    return (
      <ReportDataView
        {...props}
        columns={data?.columns}
        data={data?.data}
        isLoading={isLoading}
      />
    )
  }
  return (
    <ReportDataView
      {...props}
      host={configuration.apiUrl}
      runReport={
        currentParameterValues === runReportParams &&
        runReportParams !== undefined
      }
    />
  )
}
