import { PublicClientApplication } from '@azure/msal-browser'
import { Layout } from 'antd'
import { Footer } from 'antd/lib/layout/layout'
import { configuration } from './configuration'
import { SiteBody } from './site/SiteBody'
import { SiteHeader } from './site/SiteHeader'

function App() {
  var pca = new PublicClientApplication(configuration)
  return (
    <Layout style={{ height: '100vh' }}>
      <SiteHeader publicClientApplication={pca} />
      <SiteBody configuration={configuration} publicClientApplication={pca} />
      <Footer></Footer>
    </Layout>
  )
}

export default App
