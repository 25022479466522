import { Layout, Menu } from "antd"
import { Content } from "antd/lib/layout/layout"
import Sider from "antd/lib/layout/Sider"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import { useContext } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { QueryContext } from "../../api/QueryContext"

export const Schedules = () => {
  const queries = useContext(QueryContext)
  let { reportId } = useParams()
  const navigate = useNavigate()

  const { data } = queries.getReportSchedules()
  const reportIdNumber = parseInt(reportId ?? "")
  const items: ItemType[] | undefined = data
    ?.filter((x) => x.reportId === reportIdNumber)
    .map((x) => {
      return {
        key: x.id,
        label: x.name,
      }
    })

  return (
    <Layout hasSider>
      <Sider>
        <Menu
          items={(items ?? []).concat([
            { type: "divider" },
            { key: "new", label: "New" },
          ])}
          onClick={(x) => {
            navigate(x.key)
          }}
        />
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  )
}
