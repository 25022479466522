import { Menu, MenuProps } from "antd"
import { useContext } from "react"
import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import { QueryContext } from "../api/QueryContext"
import ReportTags from "./ReportTags"

export default function Report() {
  let { reportId } = useParams()
  let location = useLocation()
  var reportIdNumber = parseInt(reportId ?? "")

  const queries = useContext(QueryContext)
  const { data } = queries.useReport(reportIdNumber)

  const items: MenuProps["items"] = [
    {
      label: <Link to={"report"}>Report</Link>,
      key: "report",
    },
    {
      label: <Link to={"schedules"}>Schedule</Link>,
      key: "schedules",
    },
  ]

  return (
    <>
      <h2>{data?.title ?? "Loading"}</h2>
      <ReportTags
        reportIdNumber={reportIdNumber}
        key={reportIdNumber}
      ></ReportTags>
      <Menu
        mode="horizontal"
        items={items}
        selectedKeys={[
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1),
        ]}
      />
      <Outlet />
    </>
  )
}
