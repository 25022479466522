import { Button, Checkbox, Form, FormItemProps, Input } from "antd"

export const emailOptions = (
  <>
    <h2>Email Options</h2>
    <Form.Item
      label="Subject"
      name="emailSubject"
      rules={[{ required: true, message: "Subject Required" }]}
    >
      <Input />
    </Form.Item>
    {emailSelect("emailTo", "To", true)}
    {emailSelect("emailCc", "Cc")}
    {emailSelect("emailBcc", "Bcc")}

    <Form.Item label="Include Link" name="includeLink" valuePropName="checked">
      <Checkbox />
    </Form.Item>
  </>
)

function emailSelect(name: string, label: string, required?: boolean) {
  const formItemLayoutWithOutLabel: FormItemProps = {
    wrapperCol: {
      offset: 2,
      span: 10,
    },
  }

  return (
    <Form.List name={name}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              {...(index !== 0 ? formItemLayoutWithOutLabel : undefined)}
              label={index === 0 ? label : ""}
              required={required}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={
                  required
                    ? [
                        {
                          required: true,
                          whitespace: true,
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]
                    : []
                }
                noStyle
              >
                <Input placeholder="email" />
              </Form.Item>
              {fields.length > 1 ? (
                <Button
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                  icon={<>-</>}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item {...formItemLayoutWithOutLabel}>
            <Button type="dashed" onClick={() => add()} icon={<>+</>}>
              Add Email
            </Button>

            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}
