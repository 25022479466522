import { ConfigProvider } from "antd"
import en_GB from "antd/lib/locale/en_GB"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./App"
import Home from "./Home/Home"
import IdCards from "./IdCards"
import "./index.css"
import { Report, Reports } from "./Reports"
import ReportData from "./Reports/ReportData"
import ReportSummary from "./Reports/ReportSummary"
import { Schedule } from "./Reports/Schedule"
import { Schedules } from "./Reports/Schedule/Schedules"
const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <ConfigProvider locale={en_GB}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route path="idcards" element={<IdCards />} />
            <Route path="reports" element={<Reports />}>
              <Route index element={<ReportSummary />} />
              <Route path=":reportId" element={<Report />}>
                <Route path="report" element={<ReportData />} />
                <Route path="schedules" element={<Schedules />}>
                  <Route path=":reportScheduleId" element={<Schedule />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<>Page Not found</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ConfigProvider>
)
